<template>
  <v-form ref="form" class="profile-form">
    <div class="profile-form__block">
      <div class="profile-form__head">
        <h4 class="profile-form__title">Какая помощь вам требуется</h4>
        <p class="profile-form__text">Укажите, какая помощь вам необходима</p>
      </div>

      <button type="button" class="btn btn--prime btn--md" @click="openDialogServiceRequest()">Добавить</button>
    </div>
    <template v-if="loading">
      <v-progress-linear indeterminate></v-progress-linear>
    </template>
    <div v-if="serviceRequestList.length" class="profile-form__block">
      <div class="profile-form__head">
        <h4 class="profile-form__title">Услуги, в которых вы нуждаетесь</h4>
      </div>

      <div class="list-services">
        <template v-for="(service, i) of serviceRequestList">
          <BlockService
            class="block-service--collapse"
            :service="service"
            @edit="openDialogServiceRequest(i)"
            @edit-child="openDialogChildRequest(i, $event)"
            @remove="removeServiceRequest(i)"
            @changeStatus="changeStatus(service.id, $event)"
            :key="i"
          />
        </template>
      </div>
    </div>
    <v-dialog v-model="childRequestModal">
      <v-card>
        <div class="btn-close" @click="childRequestModal = false">
          <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
        </div>
        <ModalChildRequest
          v-if="childRequestModal"
          :parentRequestId="editedChildRequest && editedChildRequest.parentId"
          :request="editedChildRequest && editedChildRequest.child"
          @success-child-update="onSuccessChildUpdate"
        />
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import ModalChildRequest from '@component/Modal/ModalChildRequest';

import BlockService from '@component/Module/BlockService';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    BlockService,
    ModalChildRequest,
  },
  data() {
    return {
      loading: true,
      dialogService: false,
      activeServiceId: null,
      childRequestModal: false,
      editedChildRequest: null,
    };
  },
  watch: {
    dialogService(v) {
      if (!v) {
        this.activeServiceId = null;
      }
    },
    serviceRequestList(arr) {
      this.$emit('update:valid', Boolean(arr.length));
    },
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      serviceRequestList: state => state.user.serviceRequestList,
    }),
    activeServiceData() {
      return this.activeServiceId !== null ? this.formData.services[this.activeServiceId] : {};
    },
  },
  methods: {
    ...mapActions('user', ['getServiceRequestList', 'updateServiceRequest', 'removeServiceRequest']),
    async changeStatus(serviceRequestId, { value, done }) {
      const status = value ? 'ACT' : 'CNC';
      await this.updateServiceRequest({ id: serviceRequestId, status })
        .finally(() => done());
    },

    openDialogServiceRequest(i = null) {
      let params = {};

      if (i !== null) {
        params.data = this.serviceRequestList[i];
      } else {
        params = {};
      }
      this.$modal.show('ServiceNeed', params);
    },
    openDialogChildRequest(i, childId) {
      this.editedChildRequest = {
        parentId: this.serviceRequestList[i].id,
        child: this.serviceRequestList[i].child.find(el => el.id === childId),
      };
      this.childRequestModal = true;
    },
    onSuccessChildUpdate() {
      this.childRequestModal = false;
    },
  },
  async mounted() {
    await this.$store.dispatch('enums/getCategories');
    if (!this.serviceRequestList.length) {
      await this.getServiceRequestList();
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep {
    .block-service--collapse {
      border-bottom: 0;
      border-radius: 0;
      &:first-child {
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    }
  }
</style>
