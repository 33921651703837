<template>
  <div class="profile-form">
    <div class="profile-form__block">
      <div class="profile-form__head">
        <h4 class="profile-form__title">Добавьте способ оплаты</h4>
      </div>
      <div class="cr-radio-list">
        <template v-for="item in list">
          <label class="cr-radio" :key="item.index">
            <input
              :class="['cr-radio__field', {'cr-radio__field--checked': item.value === active}]"
              type="radio"
              :value="item.value"
              v-model="formData.radio"
              @change="change"
            />
            <div class="cr-radio__vue">
              <div class="cr-radio__text">
                <div v-if="item.icon" class="cr-radio__icon">
                  <img svg-inline :src="item.icon" alt="">
                </div>
                {{ item.label }}
              </div>
              <div class="cr-radio__box"></div>
            </div>
          </label>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    active: [Number, String],
    list: Array,
  },
  data() {
    return {
      formData: {
        radio: '',
      },
    };
  },
  methods: {
    change() {
      this.$emit('update:valid', true);
      this.$emit('change', this.formData.radio);
    }
  },
};

</script>

<style lang="scss" scoped>



</style>
