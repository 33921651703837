<template>
  <v-form
    ref="form"
    @input="$emit('update:valid', $event)"
    class="profile-form"
  >

    <div class="profile-form__block">

      <div class="profile-form__head">
        <h4 class="profile-form__title">Добавить банковскую карту</h4>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 mb--sm">

          <TextInput
            label="Номер карты"
            :rules="[
              value => !(value.replace(/\s+/g, '').length < 16) || 'Некорректный номер карты'
            ]"
            v-mask="'#### #### #### ####'"
            v-model="formData.number"
            required
          />

        </div>

        <div class="col-12 col-md-4 mb--sm">

          <TextInput
            label="Срок действия"
            placeholder="ММ / ГГ"
            v-mask="'## / ##'"
            v-model="formData.estimate"
            :rules="[
              value => checkValidityPeriod(value) || 'Недействительный срок действия'
            ]"
            required
          />

        </div>
        <div class="col-12 col-md-2 mb--sm">

          <TextInput
            label="CVV"
            type="password"
            v-model="formData.cvv"
            only-numbers
            :max="3"
            required
          />

        </div>
      </div>
<!--      TODO: Неприоритетный-->
<!--      <div class="bank-card" v-if="isVisibleBankCard">-->

<!--        <button class="bank-card__btn" type="button">-->
<!--          <img-->
<!--            svg-inline-->
<!--            src="@img/detail.svg"-->
<!--            alt=""-->
<!--          >-->
<!--        </button>-->
<!--        <svg viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'bank-card__visa'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0)" fill="#000"><path d="M33.634 29.5l-4.27 25.052h6.828L40.465 29.5h-6.831zM23.64 29.529l-6.687 17.085-.713-2.58c-1.318-3.108-5.062-7.572-9.458-10.386l6.115 20.892 7.224-.012 10.753-25.005-7.233.006z"/><path d="M13.648 31.322c-.397-1.526-1.547-1.98-2.975-2.035H.088L0 29.786c8.238 1.998 13.689 6.813 15.95 12.603l-2.302-11.067zM54.303 34.303c2.234-.035 3.853.454 5.11.96l.617.289.923-5.43c-1.352-.507-3.471-1.052-6.116-1.052-6.747 0-11.502 3.398-11.54 8.268-.043 3.598 3.388 5.607 5.98 6.806 2.66 1.23 3.553 2.011 3.54 3.109-.02 1.677-2.12 2.446-4.082 2.446-2.734 0-4.187-.378-6.43-1.312l-.88-.4-.96 5.607c1.598.7 4.547 1.303 7.608 1.334 7.177 0 11.84-3.357 11.89-8.56.03-2.847-1.791-5.02-5.737-6.802-2.388-1.162-3.85-1.935-3.835-3.108 0-1.041 1.24-2.155 3.912-2.155zM78.475 29.527H73.2c-1.638 0-2.857.446-3.577 2.08L59.485 54.564h7.17s1.17-3.087 1.435-3.763c.785 0 7.751.01 8.744.01.203.875.832 3.753.832 3.753H84l-5.525-25.038zm-8.42 16.147c.562-1.438 2.72-7.001 2.72-7.001-.037.069.557-1.449.91-2.392l.46 2.16 1.582 7.233h-5.672z"/></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h84v84H0z"/></clipPath></defs></svg>-->
<!--        <div class="bank-card__bottom">-->

<!--          <p class="bank-card__number">{{ formData.number }}</p>-->
<!--          <p class="bank-card__date">{{ formData.estimate }}</p>-->

<!--        </div>-->

<!--      </div>-->
    </div>
  </v-form>
</template>

<script>

import formData from "@mixin/formData";
import TextInput from "@component/UI/Form/TextInput";

export default {
  components: {
    TextInput,
  },
  mixins: [formData],
  computed: {
    isVisibleBankCard() {
      const formData = Object.entries(this.formData);
      return formData.some(([, value]) => value);
    }
  },
  methods: {
    checkValidityPeriod(v) {
      const [ month, year ] = v.split(' / ');
      const currentYear = String(DateTime().get('year')).slice(-2);
      return Number(month) <= 12 && Number(currentYear) <= Number(year);
    }
  }
};

</script>

<style lang="scss" scoped>

// bank-card
.bank-card {
  position: relative;
  max-width: 529px;
  min-height: 295px;
  background: #EDEDF4;
  border-radius: 14px;
  margin-bottom: 25px;
  padding: 28px 0;
  .bank-card__btn {
    position: absolute;
    top: 44px;
    right: 23px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: transparent;
    border: none;
    padding: 0;
  }
  .bank-card__visa {
    position: absolute;
    top: 44px;
    left: 35px;
    width: 84px;
    height: 84px;
  }
  .bank-card__number {
    font-size: 26px;
    line-height: 22px;
    letter-spacing: 4px;
    margin-bottom: 17px;
  }
  .bank-card__date {
    font-size: 20px;
    line-height: 22px;
    color: #B3B3B8;
  }
  .bank-card__bottom {
    position: absolute;
    left: 35px;
    bottom: 32px;
  }
}

</style>
