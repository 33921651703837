<template>
  <TextInput
    v-bind="$attrs"
    v-mask="mask"
    @input="onInput"
    :value="phoneNumber"
  ></TextInput>
</template>

<script>
import TextInput from "@component/UI/Form/TextInput";
import phoneInput from "@mixin/phoneInput";
export default {
  name: "PhoneInput",
  mixins: [phoneInput],
  components: { TextInput },
  mounted() {
    this.$nextTick(() => {
      this.onInput(this.$attrs.phone);
    });
  },
};
</script>
