<template>
  <ProfilePageWrapper
    @exit="closeEditProfile"
    @save="save"
    title="Редактирование профиля помощь для близкого"
    :sub-title="subTitles[stepActive]"
  >
    <template #nav>
      <NavSteps :list="navList" :active="stepActive" v-on:choose="setStep($event)" mode="iconList"></NavSteps>
    </template>
    <template #content>
      <template v-if="stepActive === 0">
        <DataPerson :data.sync="formData.user" is-registration-step />
      </template>
      <template v-if="stepActive === 1">
        <DocumentsPerson :data.sync="formData.user.identification" />
      </template>
      <template v-if="stepActive === 2">
        <PaymentMethod
          :active="paymentMethod"
          :list="paymentList"
          v-on:change="choosePayment"
        />
        <template v-if="paymentMethod === 'Банковская карта'">
          <PaymentCard />
        </template>
      </template>
      <template v-if="stepActive === 3">
        <template v-if="isRecipientEdit">
          <DataRelative :person-data="formData.recipient" ref="dataRelative" />
        </template>
        <template v-else-if="isRecipientCreate">
          <DataRecipient :valid.sync="steps.familyGeneral.valid" :data.sync="steps.familyGeneral.data" />
        </template>
        <template v-else>
          <div class="row">
            <div class="col-12 col-md-6 add-family-btn--wrapper">
              <button @click="openAddFamily" type="button " class="btn btn--border add-family-btn">
                <v-icon class=" add-family-btn__icon"> mdi-plus </v-icon>
                <span class="btn--text">Добавить близкого</span>
              </button>
            </div>
            <div v-for="person in user.family_set" :key="person.id" class="col-12 col-md-6">
              <label class="av-radio" @click="editPerson(person)">
                <!-- <input type="radio" :value="person.id" v-model="formData.recipient" class="av-radio__field" /> -->
                <div class="av-radio__vue">
                  <div class="av-radio__block">
                    <div class="av-radio__avatar">
                      <img
                        v-if="person.photo"
                        :src="person.photo" class="av-radio__img" alt="avatar" />
                      <img v-else src="@img/user-avatar.svg" class="av-radio__img" alt=""/>
                    </div>
                    <div class="av-radio__cont">
                      <div class="av-radio__name">{{ person | fullName }}</div>
                      <div class="av-radio__role">{{ findStates(person.state).value }}</div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </template>
      </template>
      <template v-if="stepActive === 4">
        <ServicesNeed />
      </template>
      <v-dialog v-model="dialogAccept">
        <v-card>
          <ModalAccept :title="dialogTitle" v-on:confirm="dialogConfirm($event)" />
        </v-card>
      </v-dialog>
    </template>
  </ProfilePageWrapper>
</template>

<script>
import NavSteps from '@component/Module/NavSteps';
import DataPerson from '@component/Page/Profile/DataPerson';
import DocumentsPerson from '@component/Page/Profile/DocumentsPerson';
import PaymentMethod from '@component/Page/Profile/PaymentMethod';
import PaymentCard from '@component/Page/Profile/PaymentCard';
import DataRelative from '@component/Page/Profile/DataRelative';
import ServicesNeed from '@component/Page/Profile/ServicesNeed';
import ModalAccept from '@component/Modal/ModalAccept';
import ProfilePageWrapper from '@component/Page/Profile/ProfilePageWrapper.vue';
import DataRecipient from '@component/Page/Profile/DataRecipient';

import { mapGetters, mapState, mapActions } from 'vuex';

const urlToObject = async imageUrl => {
  const response = await fetch(imageUrl);
  const imgArr = imageUrl.split('/');
  const imageName = imgArr[imgArr.length - 1];
  const blob = await response.blob();
  const file = new File([blob], imageName, {
    type: `image/${imageName.split('.')[1]}`,
    lastModified: new Date().getTime(),
  });

  return file;
};

const getClearFamilyPersoneForm = () => ({
  first_name: '',
  last_name: '',
  gender: '',
  birthday: '',
  commentary: '',
  weight: '',
  reason: '',
  relation: '',
  photo: null,
  state: '',
  mobile: '',
});
export default {
  name: 'EditProfile',
  components: {
    NavSteps,
    DataPerson,
    DocumentsPerson,
    PaymentMethod,
    PaymentCard,
    DataRelative,
    ServicesNeed,
    ModalAccept,
    ProfilePageWrapper,
    DataRecipient,
  },
  filters: {
    fullName(person) {
      return `${person.first_name} ${person.last_name}`;
    },
  },
  data() {
    return {
      stepActive: 0,
      dialogAccept: false,
      // dialogTitle: 'На ваш E-mail отправлено письмо для восстановления доступа',
      dialogTitle: 'Изменения сохранены',
      navList: [
        {
          name: 'Общие сведения',
          icon: require('@img/nav/need-general.svg'),
        },
        {
          name: 'Мои документы',
          icon: require('@img/nav/need-documents.svg'),
        },
        {
          name: 'Способ оплаты',
          icon: require('@img/nav/need-bank.svg'),
        },
        {
          name: 'Добавить близкого',
          icon: require('@img/nav/need-relative.svg'),
        },
        {
          name: 'Какая помощь требуется',
          icon: require('@img/nav/need-help.svg'),
        },
      ],
      paymentMethod: '',
      paymentList: [
        {
          value: 'Банковская карта',
          label: 'Банковская карта',
          icon: require('@img/icons/visa.svg'),
        },
        {
          value: 'Наличные',
          label: 'Наличные',
          icon: require('@img/icons/wallet.svg'),
        },
      ],
      subTitles: [
        'Укажите собственные данные',
        'Укажите собственные данные',
        'Укажите данные карты, с которой будет производится оплата',
        'Выбрать близкого',
        'Укажите какая помощь требуется вашему близкому',
      ],
      formData: {
        user: {
          first_name: '',
          last_name: '',
          gender: '',
          birthday: '',
          about: '',
          photo: '',
          identification: null,
          email: ''
        },
        recipient: null,
      },
      steps: {
        familyGeneral: {
          valid: false,
          data: getClearFamilyPersoneForm(),
        },
      },
      isRecipientEdit: false,
      isRecipientCreate: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      genders: state => state.enums.genders,
      states: state => state.enums.states,
    }),
    ...mapGetters({
      findStates: 'enums/findStates',
      findGender: 'enums/findGender',
    }),
  },
  async mounted() {
    if (!this.states.length) {
      this.getStates();
    }
    if (!this.user.id) {
      await this.getUser();
    }
    this.setUserFormData();
  },
  methods: {
    ...mapActions({
      getUser: 'user/getUser',
      getStates: 'enums/getStates',
    }),
    setUserFormData() {
      // TODO make initial set like in  LkAssistanceProvideCompany (created)
      if (!this.user) return;
      Object.entries(this.user).forEach(([key, value]) => {
        if (key in this.formData.user) {
          if (key === 'identification') {
            if (!value) {
              this.formData.user[key] = {
                number: '',
                serial: '',
                files: [],
              };
            } else {
              const { number, serial, image1, image2 } = value;
              this.formData.user[key] = {
                number,
                serial,
                files: [
                  {
                    file: null,
                    name: 'Фото паспорта (2-3 страница)',
                    preview: image1,
                  },
                  {
                    file: null,
                    name: 'Фото паспорта (4-5 страница)',
                    preview: image2,
                  },
                ],
              };
            }
          } else {
            this.formData.user[key] = value;
          }
        }
      });
    },
    setStep(index) {
      this.stepActive = index;
    },
    choosePayment(value) {
      this.paymentMethod = value;
    },
    dialogConfirm() {
      this.dialogAccept = false;
    },

    editPerson(person) {
      this.formData.recipient = person;
      this.isRecipientEdit = true;
    },
    closeEditProfile() {
      if (this.stepActive === 3 && this.isRecipientEdit) {
        this.isRecipientEdit = false;
        this.formData.recipient = null;
      } else if (this.stepActive === 3 && this.isRecipientCreate) {
        this.isRecipientCreate = false;
        this.steps.familyGeneral.data = getClearFamilyPersoneForm();
      } else {
        this.$router.push({
          name: this.$routeName.ASSISTANCE_NEED_APPLICATIONS,
        });
      }
    },
    async save() {
      if (this.stepActive === 3 && this.isRecipientEdit) {
        await this.$refs.dataRelative.updateFamily();
        await this.getUser();
        this.isRecipientEdit = false;
      }
      if (this.stepActive === 3 && this.isRecipientCreate) {
        await RequestManager.Family.create(this.user.id, this.steps.familyGeneral.data);
        await this.getUser();
        this.isRecipientCreate = false;
      } else {
        this.saveUserProfileData();
      }
    },
    async saveUserProfileData() {
      // TODO make save like in  LkAssistanceProvideCompany (saveUserProfileData)

      const { first_name, last_name, gender, birthday, about, photo, identification } = this.formData.user;
      let user = null;
      let documents = null;
      const { number, serial, files } = identification;

      let images = [];

      switch (this.stepActive) {
        case 0:
          user = { first_name, last_name, gender, birthday, about };
          if (typeof photo === 'object') {
            user = { ...user, photo };
          }
          break;
        case 1:
          for (const image of files) {
            if (image.preview.startsWith('https')) {
              const temp = await urlToObject(image.preview);
              images.push(temp);
            } else {
              images.push(image.file);
            }
          }

          documents = { number, serial, files: [...images] };
          break;
      }
      // TODO remove unnecessary updateUserProfileData action. Change on RequestManager.Register.sendUser
      const response = await this.$store.dispatch('user/updateUserProfileData', { user, documents });
      if (response) {
        this.dialogAccept = true;
      }
    },
    openAddFamily() {
      this.isRecipientCreate = true;
    },
  },
};
</script>

<style lang="scss" scoped>
// page-profile
.page-profile {
  padding: 80px 0 30px;
  .page-profile__head {
    margin-bottom: 30px;
    @include respond-to('sm') {
      text-align: center;
    }
  }
  .page-profile__btn-gp {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    .btn {
      width: 100%;
      max-width: 343px;
      margin-bottom: 20px;
    }
  }
  @include respond-to('sm') {
    padding: 100px 0 30px;
    .page-profile__head {
      margin-bottom: 40px;
    }
    .page-profile__btn-gp {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 30px;
      .btn {
        width: 48%;
        max-width: 343px;
        margin-bottom: 0px;
      }
    }
  }
  @include respond-to('md') {
    padding: 120px 0 30px;
    .page-profile__head {
      margin-bottom: 50px;
    }
  }
}
.add-family-btn {
  width: 90%;
  &:hover {
    .add-family-btn__icon {
      color: white;
    }
  }
}

.add-family-btn--wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.btn--text {
  padding-top: 4px;
}
</style>
