<template>
  <v-form ref="form" v-model="valid" class="profile-form">
    <div class="upload-ava">
      <div class="upload-ava__content">
        <div class="upload-ava__avatar">
          <img v-if="!previewImage" src="@img/user-avatar.svg" class="upload-ava__img" alt="" />
          <img v-else :src="previewImage" class="upload-ava__img" alt="" />
        </div>
        <div class="upload-ava__desc">Пожалуйста, загрузите реальную фотографию, она используется для подтверждения аккаунта</div>
        <div class="upload-ava__field">
          <input type="file" class="upload-ava__input" @change="upload($event.target.files)">
          <button type="button" class="btn btn--prime">Добавить фото</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 mb--sm">
        <TextInput
          v-model="formData.first_name"
          label="Имя"
          only-letters
          required
        />
      </div>
      <div class="col-12 col-md-6 mb--sm">
        <TextInput
          v-model="formData.last_name"
          label="Фамилия"
          only-letters
          required
        />
      </div>
      <div class="col-12 col-md-6 mb--sm">
        <Select
          label="Степень родства"
          item-text="value"
          item-value="key"
          :items="states"
          v-model="formData.state"
          required
        />
      </div>
      <div class="col-12 col-md-6 mb--sm">
        <Select
          label="Пол"
          item-text="value"
          item-value="key"
          :items="genders"
          v-model="formData.gender"
          required
        />
      </div>
      <div class="col-12 col-md-6 mb--sm">
        <DatePicker
          label="Дата рождения"
          v-model="formData.birthday"
          required
        />
      </div>
      <div class="col-12 col-md-6 mb--sm">
        <PhoneInput
          label="Телефон"
          v-model="formData.mobile"
        />
      </div>
      <div class="col-12 col-md-6 mb--sm">
        <TextInput
          v-model="formData.weight"
          label="Вес"
          required
          only-numbers
        />
      </div>
      <div class="col-12 col-md-6 mb--sm">
        <TextInput
          v-model="formData.reason"
          label="Диагноз"
          required
        />
      </div>
      <div class="col-12 col-md-6 mb--sm">
        <AddressAutocomplete
          label="Адрес места оказания услуги"
          v-model="formData.address"
        />
      </div>
      <div class="col-12 col-md-6 mb--sm">
        <TextInput
          :label="labelAbout"
          v-model="formData.description"
        />
      </div>
    </div>
  </v-form>
</template>

<script>
import TextInput from "@component/UI/Form/TextInput";
import Select from "@component/UI/Form/Select";
import DatePicker from "@component/UI/Form/DatePicker";
import formMixin from '@mixin/form-validation-preload';
import AddressAutocomplete from "@component/UI/Form/AddressAutocomplete";
import {mapState} from 'vuex';
import uploadPreview from "@mixin/uploadPreview";
import PhoneInput from "@component/UI/Form/PhoneInput";

export default {
  components: {
    PhoneInput,
    TextInput,
    DatePicker,
    Select,
    AddressAutocomplete,
  },
  mixins: [formMixin, uploadPreview],
  props: {
    personData: {
      type: Object,
      required: true,
    },

    isRegistrationStep: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      preview: null,
      formData: {
        first_name: '',
        last_name: '',
        role: '',
        gender: '',
        birthday: '',
        mobile: '',
        weight: '',
        reason: '',
        address: {},
        description: '',
        photo: null,
        state: '',
      },
      addresses: [],
    };
  },
  computed: {
    ...mapState({
      genders: state => state.enums.genders,
      states: state => state.enums.states,
      user: state => state.user.user,
    }),
    isCustomer() {
      return !this.user?.helper_mode;
    },
    isCompany() {
      return !this.user?.company?.name;
    },
    labelAbout() {
      if (this.isCustomer) {
        return 'Ваш комментарий (пожелания к заказу)';
      } else {
        if (this.isCompany) {
          return 'Ваш комментарий';
        } else {
          return 'О себе';
        }
      }
      // return this.isCustomer
      //   ? 'Ваш комментарий (пожелания к заказу)'
      //   : this.isCompany
      //     ? 'Ваш комментарий'
      //     : 'О себе';
    },
    previewImage() {
      return this.preview ? this.preview : this.formData.photo;
    }
  },
  mounted() {
    this.setModel();
    this.getFamilyAddress();
  },
  methods: {
    setModel() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = this.personData[key];
      });
    },
    async getFamilyAddress() {
      try {
        const {results} = await RequestManager.Family.getFamilyAddress(this.user.id, this.personData.id);
        this.addresses = results;
        if (results.length) {
          this.formData.address = results[0]; // Тут устанавливается первый адрес из массива, т.к. в данное время не понятно какие адреса доступны для редактирования.
        }
      }
      catch (err) {
        console.error(err);
      }
    },
    async upload(files) {
      this.formData.photo = files.item(0);
      this.preview = await this.uploadPreview(this.formData.photo);
      console.log(this.formData);
    },
    async imgAdd() {
      this.img = await this.imgRead(this.formData.photo);
    },
    async updateFamily() {

      try {
        const FD = new FormData();

        Object.entries(this.formData).forEach(([k, v]) => {
          if (v && k !== 'address') {
            if (k === 'photo') {
              if (typeof v === 'object') {
                FD.append(k, v);
              }
            } else {
              FD.append(k, v);
            }
          }
        });

        const family = await RequestManager.Family.update(this.user.id, this.personData.id, FD);

        // Функционал отключен до того времени когда будет ясно в каком формате передавать координаты
        if (this.formData.address) {
          if (!this.addresses.length) {
            const createdAddress = await RequestManager.Family.createFamilyAddress(this.user.id, this.personData.id, this.formData.address);
          } else {
            const updatedAddress = await RequestManager.Family.updateFamilyAddress(this.user.id, this.personData.id, this.formData.address.id, this.formData.address);
          }
        }
      } catch (error) {
        console.log(error);
      }

      return;
    }
  }
};

</script>

<style lang="scss" scoped>

</style>
