import { parsePhoneNumber, AsYouType } from 'libphonenumber-js';

export default {
  data() {
    return {
      maskDefault: '+# ### ### ## ##',
      maskCustom: null,
      value: ''
    };
  },
  model: {
    event: 'change',
    prop: 'phone',
  },
  watch: {
    '$attrs.phone'(v) {
      if (!v) this.value = '';
      // else this.value = v;
    }
  },
  computed: {
    mask() {
      return this.maskCustom ?? this.maskDefault;
    },
    phoneNumber() {
      return this.value;
    }
  },
  methods: {
    convertTemplateToMask(template) {
      return template
        .replace(/x/g, '#')
          .replace(/(#)/, '+');
    },

    onInput(value) {
      this.value = value;

      try {

        let v = this.phoneNumber;
        let phoneNumber = parsePhoneNumber(v, 'RU');
        let asYouType = new AsYouType(phoneNumber.country);

        asYouType.input(phoneNumber.number);

        v = asYouType?.formattedOutput ?? phoneNumber.number;

        let template = asYouType.formatter.template; //asYouType.getTemplate()


        if (template) {
          this.maskCustom = this.convertTemplateToMask(template);
        }

        // console.log(asYouType, v, template);


      } catch ({ message }) {

        console.log(message);

        this.maskCustom = null;

      }
      // eslint-disable-next-line
      this.$emit('change', typeof this.phoneNumber === 'string' ? this.phoneNumber.replace(/[^+\d]/g, '') : this.phoneNumber);
    },
  }
};
