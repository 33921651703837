<template>
  <v-form
    ref="form"
    @input="$emit('update:valid', $event)"
    class="profile-form"
  >
    <div class="upload-ava">

      <div class="upload-ava__content">

        <div class="upload-ava__avatar">
          <img src="@img/user-avatar.svg" class="upload-ava__img" alt="" v-if="!preview">
          <img :src="preview" class="upload-ava__img" alt="" v-else>
        </div>

        <div class="upload-ava__desc">Пожалуйста, загрузите реальную фотографию, она используется для подтверждения аккаунта</div>

      </div>

      <div class="upload-ava__field">
        <input type="file" class="upload-ava__input" @change="upload($event.target.files)">
        <button type="button" class="btn btn--prime">Добавить фото</button>
      </div>

    </div>
    <div class="row">
      <div class="col-12 col-md-6 mb--sm">

        <TextInput
          v-model="formData.first_name"
          label="Имя"
          name="name"
          only-letters
          required
        />

      </div>
      <div class="col-12 col-md-6 mb--sm">

        <TextInput
          label="Фамилия"
          name="surname"
          v-model="formData.last_name"
          only-letters
          required
        />

      </div>
      <div
        v-if="'state' in data"
        class="col-12 col-md-6 mb--sm"
      >

        <Select
          label="Степень родства"
          item-text="value"
          item-value="key"
          :items="states"
          v-model="formData.state"
          required
        />

      </div>
      <div class="col-12 col-md-6 mb--sm">

        <Select
          label="Пол"
          item-text="value"
          item-value="key"
          :items="genders"
          v-model="formData.gender"
          required
        />

      </div>
      <div class="col-12 col-md-6 mb--sm">

        <DatePicker
          label="Дата рождения"
          :max-date="currentDate"
          v-model="formData.birthday"
          required
        />

      </div>
      <div
        v-if="'mobile' in data"
        class="col-12 col-md-6 mb--sm"
      >

        <PhoneInput
          label="Телефон"
          v-model="formData.mobile"
        />

      </div>

      <div
        v-if="'email' in formData"
        class="col-12 col-md-6 mb--sm">

        <TextInput
          label="E-mail"
          v-model="formData.email"
          is-email
        />

      </div>

      <div class="col-12 col-md-6 mb--sm">

        <TextInput
          label="Вес"
          only-numbers
          v-model="formData.weight"
          required
        />

      </div>
      <div class="col-12 col-md-6 mb--sm">

        <TextInput
          label="Диагноз"
          v-model="formData.reason"
          required
        />

      </div>
      <div class="col-12 col-md-6 mb--sm">

        <TextInput
          :label="labelAbout"
          v-model="formData.commentary"
        />

      </div>
    </div>
  </v-form>
</template>

<script>

import TextInput from "@component/UI/Form/TextInput";
import DatePicker from "@component/UI/Form/DatePicker";
import Select from "@component/UI/Form/Select";
import uploadPreview from "@mixin/uploadPreview";
import { mapState } from 'vuex';
import formData from "@mixin/formData";
import PhoneInput from "@component/UI/Form/PhoneInput";

export default {
  components: {
    PhoneInput,
    Select,
    TextInput,
    DatePicker
  },
  props: {
    isRegistrationStep: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [uploadPreview, formData],
  data() {
    return {
      preview: null,
      currentDate: undefined,
    };
  },
  computed: {
    ...mapState({
      genders: state => state.enums.genders,
      states: state => state.enums.states,
      user: state => state.user.user
    }),
    isCustomer() {
      return !this.user?.helper_mode;
    },
    isCompany() {
      return !this.user?.company?.name;
    },
    labelAbout() {
      if (this.isCustomer) {
        return 'Ваш комментарий (пожелания к заказу)';
      } else {
        if (this.isCompany) {
          return 'Ваш комментарий';
        } else {
          return 'О себе';
        }
      }
      // return this.isCustomer
      //   ? 'Ваш комментарий (пожелания к заказу)'
      //   : this.isCompany
      //     ? 'Ваш комментарий'
      //     : 'О себе';
    },
  },
  methods: {
    async upload(files) {
      this.formData.photo = files.item(0);
      this.preview = await this.uploadPreview(this.formData.photo);
    },
  },
  async mounted() {
    this.currentDate = DateTime().format('DD.MM.YYYY');

    await this.$store.dispatch('enums/getStates');

    if (this.formData.photo) {
      this.preview = await this.uploadPreview(this.formData.photo);
    }
  }
};

</script>

<style lang="scss" scoped>

</style>
